.intro {
  color: white;
  position: relative;
}
.intro::before,
.intro::after {
  content: " ";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.intro::before {
  /* display: none; */
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}
.intro::after {
  /* display: none; */
  background: linear-gradient(
    90.3deg,
    rgba(0, 14, 27, 0.76) 0.26%,
    rgba(0, 0, 0, 0) 99.74%
  );
}
.intro__video {
  position: absolute;
  z-index: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.intro__inner {
  position: relative;
  z-index: 2;
  padding: 253px 0px 255px;
  color: #ffffff;
}
.intro__title {
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 100%;
  max-width: 582px;
}
.intro__subtitle {
  max-width: 428px;
  margin: 25px 0px 50px;
}
.intro__consultation {
  max-width: 279px;
  background: #0096dd;
  border-radius: 8px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  text-align: center;

  text-transform: uppercase;
  padding: 16px 20px;
  transition: 0.3s ease;
  border: 1.5px solid #0096dd;
  cursor: pointer;
}
.intro__consultation:hover {
  background-color: #008bcd;
}
.header {
  width: 100%;
  padding: 20px 0px;
  position: absolute;
  z-index: 200;
  border-bottom: 1px solid rgba(242, 242, 242, 0.4);
  background-color: transparent;
}
.header__inner {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.__logo .logo {
  position: relative;
  z-index: 1000;
  height: 20px;
  width: auto;
}
.logo-2 {
  width: auto;
  margin-left: 8px;
}
.header__nav nav {
  width: 381px;
  display: flex;
  justify-content: space-between;
}
.nav__link {
  cursor: pointer;
  color: #e0e0e0;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  transition: 0.3s ease;
}
.nav__link:hover {
  color: white;
}
.run__demo {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* padding: 16px 20px;
  height: 54px; */
  width: 195px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  color: black;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  transition: all 0.3s ease;
}
.run__demo:hover {
  background-color: #0084c3;
  color: white;
}
.intro__phone {
  margin-right: 10px;
}
.intro__phone * {
  fill: black;
  transition: 0.4 ease;
}
.run__demo:hover .intro__phone * {
  fill: white;
}
.change_lng {
  position: relative;
}
.language {
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: transparent;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
}
.language_text {
  text-decoration-line: underline;
  color: #ffffff;
}
.nav-language {
  display: none;
  align-items: center;
  background-color: transparent;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
}
.nav-language_choice {
  cursor: pointer;
  color: #e0e0e0;
}
.nav-language_choice.active {
  text-decoration-line: underline;
  color: #ffffff;
}

.globe {
  margin-right: 10px;
}
.language_ru {
  margin-right: 12px;
}
/* BURGER MENU */
.burger {
  display: none;
  width: 20px;
  cursor: pointer;
  background: none;
  border: none;
  position: relative;
}
.burger_item {
  display: block;
  width: 20px;
  height: 2px;
  background-color: #fff;
  font-size: 0px;
  color: transparent;
  z-index: 1000;
  cursor: pointer;
}

.burger::before,
.burger::after {
  content: " ";
  display: block;
  height: 2px;
  width: 13px;
  background-color: #fff;
  position: absolute;
  z-index: 1000;
  transition: 0.6s ease;
}
.burger::before {
  right: 2px;
  top: -7px;
}
.burger::after {
  left: 2px;
  top: 7px;
}
.burger.active span {
  opacity: 0;
}
.burger.active::after,
.burger.active::before {
  width: 18px;
  left: 0;
  top: -1px;
}
.burger.active::before {
  transform: rotate(-45deg);
}
.burger.active::after {
  transform: rotate(45deg);
}
.header__nav.show {
  display: block;
  position: absolute;
  background-color: #0096dd;

  z-index: 100;
  top: 0px;
  left: 0;
  transition: 0.6s linear;
}
.not-show {
  z-index: 1;
}
@media (max-width: 900px) {
  .mar_l_25.nav__link {
    margin-left: 10px;
  }
  .run__demo {
    background-color: transparent;
    font-size: 0;
    width: auto;
    position: relative;
  }
  .nav-language {
    display: none;
  }
  .intro__phone * {
    fill: white;
  }
}
@media (max-width: 690px) {
  .run__demo {
    margin: 0px 19px;
  }
  .run__demo:hover {
    background-color: transparent;
  }
  .nav-language {
    display: flex;
  }
  .header__inner {
    justify-content: flex-end;
  }
  .header__inner button {
    position: relative;
  }
  .intro__logo {
    position: absolute;
    z-index: 1000;
    top: 50%;
    left: 20px;
    transform: translate(0%, -50%);
  }
  .header__nav {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: -500%;
    left: 0;
    z-index: 0;
    transition: 0.6s linear;
  }
  .header__nav nav {
    width: auto;
    flex-direction: column;
    margin: 100px 0px 0px 24px;
  }
  .header__nav .nav__link {
    margin: 0 0px 40px;
    color: white;
    font-size: 24px;
    line-height: 33px;
  }
  .intro__title {
    font-weight: 700;
    font-size: 38px;
  }
  .burger {
    display: block;
  }
}
@media (max-width: 350px) {
  .logo-2 {
    display: none;
  }
  .intro__title {
    font-weight: 700;
    font-size: 32px;
  }
}
