.main__none {
  display: none;
}

.main_wrap {
  width: 100%;
}
.bug__br {
  padding: 100px 0px;
}

/* title and subtitle */

/* Contact - - - Контакты */
.contact {
  /* background-color: #f1f1f1; */
}
.contact__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 106px 0px;
}
.contact__items {
  width: 72%;
  padding: 50px;
  border: 1px solid #dddddd;
  border-radius: 20px;
}
.contact__item div {
  margin-bottom: 24px;
}
.contact__title {
  font-weight: 600;
  font-size: 32px;
  line-height: 120%;
  text-align: center;
  color: #0096dd;
  margin-bottom: 11px;
}
.contact__subtitle {
  margin-top: 12px;
  color: #222222;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 39px;
}

.contact__form {
  /* padding-left: 5%; */
}
.contact__name {
  /* margin-bottom: 24px; */
}
.contact__description {
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  /* identical to box height, or 144% */

  /* Text */
  color: #222222;
}
.contact__input {
  outline: none;
  background: #f5f5f5;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  width: 100%;
  padding: 25px 0px 25px 25px;
}
.input_text {
  padding-bottom: 124px !important;
  resize: none;
}
.contact__btn {
  margin-top: 25px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  color: #ffffff;
  background: #0096dd;
  border-radius: 8px;
  text-align: center;
  padding: 18px 38px;
  height: 58px;
  transition: 0.3s ease;
}
.contact__btn:hover {
  background: #008bcd;
}
@media (max-width: 1000px) {
  .contact__inner {
    flex-direction: column;
    min-width: 326px;
  }

  .contact__subtitle {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    padding-right: 0px;
  }
  .contact__text {
    text-align: center;
  }
  .contact__form {
  }
}
@media (max-width: 900px) {
  .contact__items {
    width: 100%;
  }
}
@media (max-width: 700px) {
  .contact__title {
    font-weight: 400;
    font-size: 30px;
    line-height: 120%;
  }
  .contact__items {
    padding: 22px;
  }
  .contact__btn {
    width: 100%;
  }
}
