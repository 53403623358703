/*  */

.section {
  padding-top: 130px;
  padding-bottom: 155px;
  display: flex;
  align-items: center;
}

.section__text {
  width: 50%;
}
.section__title {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 65px;

  /* Main Blue */
  color: #0096dd;
}
.section__subtitle {
  margin-top: 25px;
  padding-right: 20px;
  color: #222222;
  line-height: 28px;
}
.section__list {
  width: 50%;
}
.list__item {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  color: #222222;

  display: flex;
  align-items: center;
}
.list__item .list__item-image {
  margin-right: 25px;
  min-width: 56px;
  min-height: 56px;
}
.city,
.photo__search {
  margin-top: 25px;
}

/*  */
/*  */
/*  */
/*  */
/*  */
/*  */

.products {
  background: #f8f8f8;
  padding: 100px 0px 114px;
}

.products__inner {
  display: flex;
  justify-content: space-around;
}
.product__item {
  width: 32.433%;
  box-shadow: 2px 4px 8px rgba(171, 171, 171, 0.25);
  border-radius: 16px;
  background-color: #fff;
}
.product__img img {
  width: 100%;
  border-radius: 16px 16px 0px 0px;
}
.product__content {
  position: relative;
  top: -5px;
  width: 100%;
  padding: 48px 0px;
  background: #ffffff;
  text-align: center;
  border-radius: 0px 0px 16px 16px;
}
.product__title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  /* identical to box height, or 29px */
  text-align: center;

  /* Black */
  color: #1d1d1d;
}
.product__subtitle {
  max-width: 718px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 82px;
  text-align: center;
  padding: 0px 40px;
  /* Text */
  color: #222222;
}
.productlink-position {
  position: absolute;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, -50%);
}
.product__link {
  cursor: pointer;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #0096dd;
  position: relative;
  left: -10px;
}
.product__icon,
._hover {
  position: absolute;
  right: -30px;
  top: 2px;
  transition: 0.3s ease;
}

._hover {
  opacity: 0;
}
.product__link:hover .product__icon {
  opacity: 0;
  visibility: hidden;
}
.product__link:hover ._hover {
  opacity: 1;
}

@media (max-width: 900px) {
  .section {
    flex-direction: column;
  }
  .section__text,
  .section__list {
    width: 100%;
  }
  .section__subtitle {
    padding-right: 0;
  }
  .section__list {
    margin-top: 60px;
  }
  .products {
    padding-bottom: 72px;
  }
  .products__inner {
    flex-direction: column;
    align-items: center;
  }
  .product__item {
    min-width: 327px;
    width: 70%;
    margin-bottom: 40px;
  }
}
@media (max-width: 550px) {
  .product__subtitle {
    padding: 0px;
    font-size: 16px;
  }
}
@media (max-width: 350px) {
  .list__item .list__item-image {
    margin-right: 25px;
    min-width: 46px;
    min-height: 46px;
    width: 46px;
    height: 46px;
  }
  .product__item {
    min-width: 250px;
  }
}
