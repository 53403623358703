@font-face {
  font-family: "NunitoSans";
  font-weight: 600;
  src: local("Lato"), url(./assets/fonts/NunitoSans-SemiBold.ttf) format("opentype");
}

@font-face {
  font-family: "NunitoSans";
  font-weight: 400;
  src: local("Lato"), url(./assets/fonts/NunitoSans-Regular.ttf) format("opentype");
}

@font-face {
  font-family: "NunitoSans";
  font-weight: 700;
  src: local("Lato"), url(./assets/fonts/NunitoSans-Bold.ttf) format("opentype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "NunitoSans";
  text-decoration: none;
}
html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}
input,
button {
  display: block;
  border: none;
}
h3 {
  font-weight: 400;
  font-size: 40px;
  line-height: 55px;
  color: #0096dd;
}
a.disabled {
  pointer-events: none;
}
.none {
  display: none !important;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 5px;
}
.mar_l_50 {
  margin-left: 50px;
}
.mar_l_25 {
  margin-left: 25px;
}
.niz-25 {
  margin-bottom: 25px;
}
.niz-10 {
  margin-bottom: 10px;
}
.pr-10 {
  padding-right: 10px;
}
.p-betw {
  padding: 0 46px !important;
}
.__title {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 65px;
  text-align: center;

  /* Main Blue */
  color: #0096dd;
}
.__subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 33px;
}
.cursor__pointer {
  cursor: pointer;
}
@media (max-width: 900px) {
  .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 20px;
  }
  .p-betw {
    padding: 0 8px !important;
  }
}
@media (max-width: 600px) {
  .container {
    padding: 0 10px;
  }
  .__title {
    font-weight: 600;
    font-size: 36px;
    line-height: 49px;
    text-align: center;
  }
}
@media (max-width: 400px) {
  .App {
    overflow: hidden;
  }
  .container {
    padding: 0 20px;
  }
}
