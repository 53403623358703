.contact__modal-wrap {
  background-color: #f8f8f8;
  position: fixed;
  z-index: 1100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: none;
}
.contact__modal-wrap.active {
  display: flex;
}
.contact__modal {
  position: relative;
  width: 60%;
  padding: 50px;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 20px;
}
.contact__modal-title {
  font-weight: 600;
  font-size: 32px;
  line-height: 120%;
  text-align: center;
  color: #0096dd;
  margin-bottom: 35px;
}

.contact__modal .contact__input {
  padding: 19px 0px 19px 20px;
}
.contact__modal-close {
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
}
.close_vector {
  width: 24px;
  height: 24px;
  position: relative;
}
.close_vector::after,
.close_vector::before {
  content: "";
  position: absolute;
  top: 10px;
  background: #9798a9;
  height: 2px;
  width: 24px;
}
.close_vector::after {
  transform: rotate(45deg);
}
.close_vector::before {
  transform: rotate(-45deg);
}
@media (max-width: 900px) {
  .contact__modal-title {
    font-size: 26px;
  }
  .contact__modal {
    width: 88%;
    padding: 50px 30px;
  }
}
@media (max-width: 400px) {
  .contact__modal {
    width: 95%;
    padding: 30px 5px;
  }
}
