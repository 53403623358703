.solution__inner {
  padding-top: 100px;
}
.solution__item {
  display: flex;
  align-items: center;
  margin-bottom: 144px;
}
.solution__content {
  width: 50%;
  padding: 0px 10px;
}
.solution__image {
  width: 50%;
}
.solution__image img {
  width: 100%;
}
.solution__suptitle {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;

  /* Gray 3 */
  color: #828282;
}
.solution__title {
  margin: 25px 0px;
}
.solution__list {
  padding-left: 1em;
}
.solution__list li {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  /* or 128% */

  /* Text */
  color: #222222;
}
.solution__demo {
  cursor: pointer;
  min-width: 167px;
  width: 167px;
  text-align: center;
  padding: 16px 0px;
  border: 1.5px solid #0096dd;
  color: #0096dd;
  border-radius: 8px;
  transition: 0.3s ease;
  display: block;
}
.solution__demo:hover {
  background: #0096dd;
  color: white;
}

@media (max-width: 900px) {
  .solution__item {
    flex-direction: column-reverse;
    margin-bottom: 100px;
  }
  .solution__content,
  .solution__image {
    width: 100%;
    margin: 0;
  }
  .solution__image {
    order: 1;
    margin-bottom: 60px;
  }
}
@media (max-width: 700px) {
  .solution__title {
    font-weight: 400;
    font-size: 30px;
    line-height: 120%;
  }
}
