
.videoA .video__none {
  display: none;
}
.videoA .main__none{
  display: block;
}
.videoA .header__nav nav{
  width: 300px;
}

.video__intro {
  position: relative;
  min-height: 800px;
}
.video__intro::before,
.video__intro::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
}
.video__intro::before {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.82) 0%,
    rgba(0, 150, 221, 0) 100%
  );
}
.video__intro::after {
  background: linear-gradient(
    109.41deg,
    rgba(0, 0, 0, 0.81) 17.36%,
    rgba(0, 150, 221, 0) 100.73%
  );
}
.video__intro img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.73) 6.18%,
    rgba(0, 150, 221, 0) 100%
  );
}
.video__intro .container {
  color: #ffffff;
  position: relative;
  z-index: 10;
  padding-top: 213px;
}
.video__intro img {
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.video__intro-title {
  text-align: center;
  font-weight: 700;
  font-size: 56px;
  line-height: 100%;
}
.video__intro-subtitle {
  margin-top: 25px;
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
}
.video__intro-runDemo {
  margin: 0 auto;
  margin-top: 50px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  padding: 16px 20px;
  background: #0096dd;
  border-radius: 8px;
  width: fit-content;
}
.video__info,
.video__demo {
  background: #000e15;
  color: #ffffff;
}
.video__info {
  padding-top: 150px;
}
.video__info-title {
  font-weight: 600;
  font-size: 48px;
  line-height: 65px;
  color: #ffffff;
}
.video__info-inner {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.video__info-block {
  width: 48%;
  margin-bottom: 70px;
}

.video__info-subtitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  margin-top: 40px;
  margin-bottom: 25px;
}
.video__info-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
}

.video__demo {
  padding-top: 200px;
  padding-bottom: 150px;
}
.demo__inner {
  background: #0096dd;
  border-radius: 16px;
  padding: 100px 0px;
  /* padding: 100px; */
  text-align: center;
}
.demo__text {
  font-weight: 400;
  font-size: 32px;
  line-height: 120%;
  color: #ffff;
}
.demo__text p {
  width: 100%;
}
.demo__button {
  width: fit-content;
  background: #ffffff;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  margin: 50px auto;
  text-transform: uppercase;
  color: #222222;
  padding: 16px 20px;
}

@media (max-width: 690px) {
  .video__intro-title {
    font-size: 38px;
  }
  .video__intro-subtitle {
    font-size: 18px;
  }
  .video__info-block {
    width: 100%;
  }
  .videoA .header__nav {
    visibility: inherit;
  }
}
@media (max-width: 480px) {
  .demo__text p {
    display: inline;
  }
}
