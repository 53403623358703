/* Scenario  - - - Сценарии Использования*/
.scenario {
  background: #f8f8f8;
  padding-top: 80px;
  padding-bottom: 50px;
}
.scenario .__subtitle {
  max-width: 718px;
  text-align: center;
  margin: 25px auto;
  margin-bottom: 35px;
}
.scenario__inner {
  display: flex;
  justify-content: space-between;
}
.scenario__item {
  width: 29.38%;
}
.scenario__title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  /* identical to box height, or 29px */
  color: #222222;
  margin-top: 50px;
  margin-bottom: 25px;
}
.scenario__subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  /* or 128% */

  color: #000000;
}
/* Why us */
.why__inner {
  display: flex;
  align-items: center;
  padding: 140px 0px 70px;
}
.why__image {
  width: 50%;
  margin-right: 25px;
}
.why__image img {
  width: 100%;
}
.why__content {
  padding: 50px 50px 34px 50px;
  border: 1px solid #f1f1f1;
  border-radius: 16px;
}
.why__list li {
  max-width: 467px;
  list-style-type: none;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  /* or 128% */

  /* Black — 90 */
  color: #1a1a1a;
  margin-bottom: 16px;
  position: relative;
  transform: translate(35px, 0px);
}
.why__list li img {
  position: absolute;
  left: -35px;
}
/* News - - - Новости */
.news {
  padding: 80px 0px 102px;
}
.news__inner {
  margin-top: 105px;
  display: flex;
  justify-content: center;
}
.news__image {
  height: 100%;
  position: relative;
}
.news__image::after {
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 98.9%;
  position: absolute;
  z-index: 1;
  border-radius: 16px;
  background: linear-gradient(
    211.65deg,
    rgba(255, 255, 255, 0) -37.36%,
    #222222 80.13%
  );
  background-blend-mode: multiply;
}
.news__item {
  width: 50%;
  position: relative;
}
.news__content {
  width: calc(100% - 35px);
  position: absolute;
  z-index: 2;
  bottom: 41px;
  left: 25px;
  color: white;
}
.news__image img {
  width: 100%;
}
.news__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
}
.news__info {
  margin: 15px 51px 31px 0px;

  font-size: 16px;
  line-height: 23px;
}
.news__link {
  background-color: white;
  padding: 16px 20px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #222222;
  transition: 0.3s ease;
}
.news__link:hover {
  color: white;
  background-color: #0084c3;
}
@media (max-width: 1000px) {
  .news__inner {
    flex-direction: column;
    align-items: center;
  }
  .news__item {
    margin: 0;
    max-width: 562.5px;
    width: 100%;
    min-width: 327;
  }
  .news__item.mar_l_25 {
    margin-top: 40px;
  }
  .news__image img {
    /*min-height: 436px;*/
  }
}
@media (max-width: 900px) {
  .scenario {
    padding-bottom: 0;
  }
  .scenario__inner {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .scenario__item {
    max-width: 330px;
    width: auto;
    margin-bottom: 78px;
  }
  .scenario__content {
    max-width: 330px;
  }
  .why__inner {
    padding-top: 73px;
    flex-direction: column-reverse;
  }
  .why__content {
    margin-bottom: 48px;
  }
  .why__image {
    width: auto;
  }
}
@media (max-width: 700px) {
  .news__image img {
    /*min-height: 336px;*/
  }
}
@media (max-width: 500px) {
  .news__image img {
    min-height: 436px;
  }
}
