.footer {
  background-color: #00111B;
  color: white;
}
.__logo-footer .logo {
  position: relative;
  z-index: 1000;
  height: 27px;
  width: auto;
}
.niz-25 {
  margin-bottom: 25px;
}
.footer__inner {
  padding: 100px 0px;
  display: flex;
  justify-content: space-between;
}
.footer__title {
  font-weight: 600;
  font-size: 32px;
  line-height: 120%;
  text-align: center;
  /* Light / 01 */
  color: #ffffff;
}
.footer__item {
  width: 25%;
}
.footer__branding {
  width: 38%;
  padding-right: 10.8%;
}
.footer__menu {
  width: 28%;
}
.footer__contact {
  width: 28%;
}
.footer__social {
  width: 20%;
  text-align: end;
}
.social__link {
  margin-right: 12px;
  transition: all o.3s ease;
}
.social__link:hover * {
  fill: #0096dd;
}
.footer__menu-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #ffffff;
}
.footer__menu-item .footer__menu-img {
  margin-right: 15px;
}
.footer__minititle {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  /* identical to box height, or 29px */
  color: #ffffff;
}
.footer__newsletter {
  padding-left: 50px;
  border-left: 1px solid #cacaca;
}

.footer__input {
  width: 100%;
  background-color: transparent;
  border: 2px solid #e5e5e5;
  border-radius: 8px;
  color: #e5e5e5;
  padding: 25px 0px 25px 25px;
  outline: none;
}
.footer__checkbox {
  cursor: pointer;
  width: 25px;
  height: 25px;
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.footer__checkbox + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  font-weight: 400;
  font-size: 16px;
}
.footer__checkbox + label::before {
  cursor: pointer;
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 2px solid #9e9e9e;
  border-radius: 2px;
  margin-right: 20px;
  line-height: 23px;
}
.footer__checkbox:checked + label::before {
  border-color: #0b76ef;
  background: url("../assets/icon/Icon_True.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 110% 110%;
  background-color: white;
}
.footer__checkbox.wrong + label::before {
  border: 2px solid red;
}
.footer__btn {
  width: 100%;
  padding: 24px 0px;
  border-radius: 8px;
  background-color: white;
  color: black;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  transition: 0.3s ease;
  border: none;
  /* pointer-events: none; */
}
.footer__btn:hover {
  background: #008bcd;
  color: white;
}
.footer__btn.event__checkbox {
  pointer-events: auto;
}
.footer__border {
  width: 100%;
  position: absolute;
  left: 0;
  height: 1px;
  background-color: #999999;
  font-size: 0;
  color: transparent;
}
.end {
  /* border-top: 1px solid #999999; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: #9e9e9e;
  padding: 45px 0px 45px;
}

.end__link {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  /* identical to box height, or 144% */
  color: #9e9e9e;
  transition: 0.4s ease;
}
.end__link:hover {
  color: white;
}
.non_social {
  display: none;
}
@media (max-width: 1000px) {
  .footer__menu {
    width: 20%;
  }
}
@media (max-width: 900px) {
  .footer__branding {
    padding-right: 0%;
  }
  .footer__inner {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .active__social {
    display: none;
  }
  .non_social {
    display: block;
  }
  .footer__social {
    text-align: start;
    width: 100%;
  }
  .footer__btn {
    max-width: 315px;
  }
}
@media (max-width: 700px) {
  .footer__branding {
    padding-right: 40px;
    margin-bottom: 50px;
  }
  .footer__contact {
    margin-top: 25px;
  }
  .end__links {
    width: 100%;
    margin-top: 30px;
  }
}
@media (max-width: 700px) {
  .footer__item {
    width: 100%;
  }
}
